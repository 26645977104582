<!-- 考试大类 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<script>
  import Layout from "@/views/layouts/main";
  import UserHeader from "../user-header.vue";

  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      UserHeader

    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "用户管理",
            active: true
          }
        ],
        checked: true,

      };
    }
  };
</script>

<template>
  <Layout>
    <UserHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="check-table-top">
              <div class="flexList">
                <b-button v-b-modal.add-insititution variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加机构用户</b-button>
                <b-button variant="outline-info" class="flexList mr-2 condition">批量通过勾选</b-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover table-bordered ">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 2%;"></th>
                    <th style="width: 3%;">序号</th>
                    <th style="width: 5%;">姓名</th>
                    <th style="width:12%;">身份证号</th>
                    <th>所属机构</th>
                    <th style="width: 8%;">角色</th>
                    <th style="width: 7%;">职务</th>
                    <th style="width: 5%;">权限</th>
                    <th style="width: 8%;">电话</th>
                    <th style="width: 18%;">审核</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><input type="checkbox" value id="defaultCheck1" /></td>
                    <td>1</td>
                    <td><a href="/admin/institutionData" class="blue-font">王小川</a></td>
                    <td>5100000000000019876</td>
                    <td><a href="" class="blue-font ">四川省房建局</a></td>
                    <td>机构管理员</td>
                    <td>已审核</td>
                    <td>11011</td>
                    <td>13890660600</td>
                    <td>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs">通过 </b-button>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs">不通过 </b-button>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs"> 删除 </b-button>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" value id="defaultCheck1" /></td>
                    <td>1</td>
                    <td><a href="/admin/institutionData" class="blue-font">姜学坤</a></td>
                    <td>5100000000000019876</td>
                    <td><a href="" class="blue-font ">四川省房建局</a></td>
                    <td>机构管理员</td>
                    <td>已审核</td>
                    <td>11011</td>
                    <td>13890660600</td>
                    <td>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs">通过 </b-button>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs">不通过 </b-button>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs"> 删除 </b-button>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" value id="defaultCheck1" /></td>
                    <td>1</td>
                    <td><a href="/admin/institutionData" class="blue-font">姜学坤</a></td>
                    <td>5100000000000019876</td>
                    <td><a href="" class="blue-font ">四川省房建局</a></td>
                    <td>机构管理员</td>
                    <td>已审核</td>
                    <td>11011</td>
                    <td>13890660600</td>
                    <td>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs">通过 </b-button>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs">不通过 </b-button>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs"> 删除 </b-button>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" value id="defaultCheck1" /></td>
                    <td>1</td>
                    <td><a href="/admin/institutionData" class="blue-font">姜学坤</a></td>
                    <td>5100000000000019876</td>
                    <td><a href="" class="blue-font ">四川省房建局</a></td>
                    <td>机构管理员</td>
                    <td>已审核</td>
                    <td>11011</td>
                    <td>13890660600</td>
                    <td>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs">通过 </b-button>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs">不通过 </b-button>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs"> 删除 </b-button>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" value id="defaultCheck1" /></td>
                    <td>1</td>
                    <td><a href="/admin/institutionData" class="blue-font">姜学坤</a></td>
                    <td>5100000000000019876</td>
                    <td><a href="" class="blue-font ">四川省房建局</a></td>
                    <td>机构管理员</td>
                    <td>已审核</td>
                    <td>11011</td>
                    <td>13890660600</td>
                    <td>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs">通过 </b-button>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs">不通过 </b-button>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs"> 删除 </b-button>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" value id="defaultCheck1" /></td>
                    <td>1</td>
                    <td><a href="/admin/institutionData" class="blue-font">姜学坤</a></td>
                    <td>5100000000000019876</td>
                    <td><a href="" class="blue-font ">四川省房建局</a></td>
                    <td>机构管理员</td>
                    <td>已审核</td>
                    <td>11011</td>
                    <td>13890660600</td>
                    <td>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs">通过 </b-button>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs">不通过 </b-button>
                      <b-button variant="outline-primary" class="mr-2 text-dark h30 w-xs"> 删除 </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <b-modal id="add-insititution" centered title="添加机构用户" size="lg" title-class="font-18 big-blue-title" hide-footer>
      <div class="insti-box ">
        <div class="d-flex mb-3">
          <div class="insti-name flexList"><i class="iconfont icon-idcard"></i>机构</div>
          <div class="insti-val flexList">
            <select name="" class="form-control form-select mr-2">
              <option value=""> 输入关键字查询</option>
              <option value=""> 西华大学</option>
              <option value="">四川省人社厅</option>
              <option value="">四川省房建局</option>
              <option value="">内江市人民政府</option>
              <option value="">乐山市人民政府</option>
              <option value="">德阳市</option>
              <option value="">达州市</option>
            </select>
            
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList "><i class="iconfont icon-idcard"></i>姓名</div>
          <div class="insti-val flexList">
            <input type="text" name="" id="" value="" class="no-border" />
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList "><i class="iconfont icon-idcard"></i> 身份证号</div>
          <div class="insti-val flexList">
            <input type="text" name="" id="" value="" class="no-border" />
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList "><i class="iconfont icon-idcard"></i> 部门</div>
          <div class="insti-val flexList">
            <select name="" class="form-control form-select mr-2">
              <option value=""> 综合科</option>
              <option value=""> 人事科</option>
            </select>
            
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList "><i class="iconfont icon-idcard"></i> 职务</div>
          <div class="insti-val flexList">
            <input type="text" name="" id="" value="" class="no-border" />
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList "><i class="iconfont icon-idcard"></i> 类别</div>
          <div class="insti-val flexList">
            <select name="" class="form-control form-select mr-2">
              <option value=""> 机构管理员</option>
              <option value=""> 机构一般审核员</option>
              <option value=""> 机构终审员</option>
            </select>
            
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList "><i class="iconfont icon-idcard"></i> 权限</div>
          <div class="insti-val flexList">
            <select name="" class="form-control form-select mr-2">
              <option value=""> 默认权限</option>
              <option value=""> 审核权限</option>
            </select>
    
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList "><i class="iconfont icon-cell_phone"></i> 手机</div>
          <div class="insti-val flexList">
            <input type="tel" name="" id="" value="" class="no-border" />
          </div>
        </div>
        <div class="d-flex ">
          <div class="insti-name flexList "><i class="iconfont icon-a-md-lock_outlineCopy"></i> 密码
          </div>
          <div class="insti-val flexList">
            <input type="text" name="" id="" value="" class="no-border" />
          </div>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="sub-btn btn btn-info ">添加机构用户</button>
      </div>
    </b-modal>
    <!-- 弹窗结束 -->

  </Layout>
</template>
